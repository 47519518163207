import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import shippingService from "./shipping-service";
import BackToTop from "../../common/back-to-top.vue";
export default {
  name: "Fulfillment Hold Release",
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt("userID"),
      actionList: [],
      favouriteList: [],
      mainPage: "",
      subPage: "",
      backSlash: true,
      pageUrl: "",
      showAdd: false,
      refresh: true,
      selectedAction: "",
      actionData: [],
      selectedBusiness: "",
      businessData: [],
      selectedCountry: "USA",
      countryData: [],
      selectedState: "",
      stateData: [],
      selectedSO: "",
      SOData: [],
      selectedPartNo: "",
      partNOData: [],
      selectedWarehouse: "",
      warehouseData: [],
      searchRecords: "",
      releaseDialog: false,
      totalRecords: 0,
      releaseItems: [],
      releaseHoldBtn: true,
      businessChanged: true,
      countryChanged: true,
      stateChanged: true,
      SOChanged: true,
      partNumChanged: true,
      wareChanged: true,
      actionChanged: false,
      buttonName: "Release Hold",
      soArrayforHoldRelease: [],
      checkRecords: false,
      headersRelease: [
        { text: "Order#", value: "Order#", class: "primary customwhite--text" },
        { text: "Business", value: "Business", class: "primary customwhite--text" },
        { text: "Multiline", value: "Multiline", class: "primary customwhite--text" },
        { text: "Country", value: "Country", class: "primary customwhite--text" },
        { text: "State", value: "State", class: "primary customwhite--text" },
        { text: "Stage Date", value: "Stage Date", class: "primary customwhite--text" },
        { text: "Carrier/SL", value: "Carrier/SL", class: "primary customwhite--text" },
      ],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.getActionData();
  },
  mounted() {},
  methods: {
    //Reset Function
    resetFunction() {
      this.selectedAction = "";
      this.actionData = [];
      this.selectedBusiness = "";
      this.businessData = [];
      this.selectedCountry = "USA";
      this.countryData = [];
      this.selectedState = "";
      this.stateData = [];
      this.selectedSO = "";
      this.SOData = [];
      this.selectedPartNo = "";
      this.partNOData = [];
      this.selectedWarehouse = "";
      this.warehouseData = [];
      this.releaseDialog = false;
      this.totalRecords = 0;
      this.releaseItems = [];
      this.releaseHoldBtn = true;
      this.businessChanged = true;
      this.countryChanged = true;
      this.stateChanged = true;
      this.SOChanged = true;
      this.partNumChanged = true;
      this.wareChanged = true;
      this.actionChanged = false;
      this.buttonName = "Release Hold";
      this.checkRecords = false;
      this.getActionData();
    },
    //Button Name
    holdButton() {
      if (this.selectedAction == "H") {
        this.buttonName = "Release Hold";
      } else if (this.selectedAction == "O") {
        this.buttonName = "Apply Hold";
      }
    },
    //Get Action Dropdown
    async getActionData() {
      let obj = {
        pass: 1,
        status: "",
        bu_key: 0,
        country: "",
        state: "",
        sotype_key: 0,
        part_key: 0,
        oware_key: 0,
        InputJson: "",
        user_key: parseInt(this.userId),
      };
      let showMessage = false;
      let actionData = await shippingService.fulfilmentHoldRelease("post", obj, showMessage);
      if (actionData != null && actionData != undefined && actionData != {}) {
        this.actionData = actionData;
      }
    },
    //Action changed
    actionSelected() {
      this.selectedRelease = [];
      this.totalRecords = 0;
      this.releaseItems = [];
      this.countryChanged = true;
      this.stateChanged = true;
      this.SOChanged = true;
      this.partNumChanged = true;
      this.wareChanged = true;
      this.selectedBusiness = "";
      this.selectedCountry = "";
      this.selectedState = "";
      this.selectedSO = "";
      this.selectedPartNo = "";
      this.selectedWarehouse = "";
      this.businessChanged = false;
      this.getBusinessData();
      this.holdButton();
    },
    //Get Business Data
    async getBusinessData() {
      let obj = {
        pass: "2",
        status: this.selectedAction,
        bu_key: 0,
        country: "",
        state: "",
        sotype_key: 0,
        part_key: 0,
        oware_key: 0,
        InputJson: "",
        user_key: parseInt(this.userId),
      };
      let showMessage = false;
      let busiData = await shippingService.fulfilmentHoldRelease("post", obj, showMessage);
      if (busiData != null && busiData != undefined && busiData != {}) {
        this.businessData = busiData;
      }
    },
    //Get Country Data
    async getCountryData() {
      this.selectedRelease = [];
      this.totalRecords = 0;
      this.releaseItems = [];
      this.stateChanged = true;
      this.SOChanged = true;
      this.partNumChanged = true;
      this.wareChanged = true;
      let obj = {
        pass: "3",
        status: this.selectedAction,
        bu_key: parseInt(this.selectedBusiness),
        country: "",
        state: "",
        sotype_key: 0,
        part_key: 0,
        oware_key: 0,
        InputJson: "",
        user_key: parseInt(this.userId),
      };
      let showMessage = false;
      let country = await shippingService.fulfilmentHoldRelease("post", obj, showMessage);
      if (country.message == "NA") {
        let Alert = {
          type: "error",
          isShow: true,
          message: "There are no countries based on the selected criteria",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      } else {
        this.countryData = country;
        this.selectedCountry = "";
        this.selectedState = "";
        this.selectedSO = "";
        this.selectedPartNo = "";
        this.selectedWarehouse = "";
        this.countryChanged = false;
      }
    },
    //Country changed
    async countrySelected() {
      this.selectedRelease = [];
      this.totalRecords = 0;
      this.releaseItems = [];
      this.SOChanged = true;
      this.partNumChanged = true;
      this.wareChanged = true;
      this.selectedState = "";
      this.selectedSO = "";
      this.selectedPartNo = "";
      this.selectedWarehouse = "";
      this.stateChanged = false;
      let obj = {
        pass: "6",
        status: this.selectedAction,
        bu_key: parseInt(this.selectedBusiness),
        country: this.selectedCountry,
        state: "",
        sotype_key: 0,
        part_key: 0,
        oware_key: 0,
        InputJson: "",
        user_key: parseInt(this.userId),
      };
      let showMessage = false;
      let state = await shippingService.fulfilmentHoldRelease("post", obj, showMessage);
      if (state.message == "NA") {
        let Alert = {
          type: "error",
          isShow: true,
          message: "There are no states based on the selected criteria",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      } else {
        this.stateData = state;
      }
    },
    //State changed
    async stateSelected() {
      this.selectedRelease = [];
      this.totalRecords = 0;
      this.releaseItems = [];
      this.partNumChanged = true;
      this.wareChanged = true;
      this.selectedSO = "";
      this.selectedPartNo = "";
      this.selectedWarehouse = "";
      this.SOChanged = false;
      let obj = {
        pass: "7",
        status: this.selectedAction,
        bu_key: parseInt(this.selectedBusiness),
        country: this.selectedCountry,
        state: this.selectedState,
        sotype_key: 0,
        part_key: 0,
        oware_key: 0,
        InputJson: "",
        user_key: parseInt(this.userId),
      };
      let showMessage = false;
      let soTypes = await shippingService.fulfilmentHoldRelease("post", obj, showMessage);
      if (soTypes.message == "NA") {
        let Alert = {
          type: "error",
          isShow: true,
          message: "There are no SO types based on the selected criteria",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      } else {
        this.SOData = soTypes;
      }
    },
    //SO Type changed
    async soTypeSelected() {
      this.selectedRelease = [];
      this.totalRecords = 0;
      this.releaseItems = [];
      this.wareChanged = true;
      this.selectedPartNo = "";
      this.selectedWarehouse = "";
      this.partNumChanged = false;
      let obj = {
        pass: "8",
        status: this.selectedAction,
        bu_key: parseInt(this.selectedBusiness),
        country: this.selectedCountry,
        state: this.selectedState,
        sotype_key: parseInt(this.selectedSO),
        part_key: 0,
        oware_key: 0,
        InputJson: "",
        user_key: parseInt(this.userId),
      };
      let showMessage = false;
      let partNum = await shippingService.fulfilmentHoldRelease("post", obj, showMessage);
      if (partNum.message == "NA") {
        let Alert = {
          type: "error",
          isShow: true,
          message: "There are no part numbers based on the selected criteria",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      } else {
        this.partNOData = partNum;
      }
    },
    //Part Number changed
    async partNumberSelected() {
      this.selectedRelease = [];
      this.totalRecords = 0;
      this.releaseItems = [];
      this.selectedWarehouse = "";
      this.wareChanged = false;
      let obj = {
        pass: "9",
        status: this.selectedAction,
        bu_key: parseInt(this.selectedBusiness),
        country: this.selectedCountry,
        state: this.selectedState,
        sotype_key: parseInt(this.selectedSO),
        part_key: parseInt(this.selectedPartNo),
        oware_key: 0,
        InputJson: "",
        user_key: parseInt(this.userId),
      };
      let showMessage = false;
      let warehouse = await shippingService.fulfilmentHoldRelease("post", obj, showMessage);
      if (warehouse.message == "NA") {
        let Alert = {
          type: "error",
          isShow: true,
          message: "There are no warehouses based on the selected criteria",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      } else {
        this.warehouseData = warehouse;
      }
    },
    //Search click event
    onSearch() {
      this.releaseItems = [];
      this.selectedRelease = [];
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let obj = {
        pass: "4",
        status: this.selectedAction,
        bu_key: parseInt(this.selectedBusiness),
        country: this.selectedCountry,
        state: this.selectedState,
        sotype_key: parseInt(this.selectedSO),
        part_key: parseInt(this.selectedPartNo),
        oware_key: parseInt(this.selectedWarehouse),
        InputJson: "",
        user_key: parseInt(this.userId),
      };
      this.axios
        .post("sh/shipping_fulfillment_hold_release", obj)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            if (response.data.body.message !== null) {
              let responseData = JSON.parse(response.data.body.message);
              const newArr = responseData.map((obj) => {
                return {
                  ...obj,
                  "Stage Date": Utility.convertESTToLocal(obj["Stage Date"]),
                };
              });
              this.releaseItems = newArr;
              this.totalRecords = responseData.length;
              this.releaseHoldBtn = false;
            } else {
              this.releaseItems = [];
              this.totalRecords = 0;
              this.releaseHoldBtn = true;
              if (!this.checkRecords) {
                let Alert = {
                  type: "error",
                  isShow: true,
                  message: "There are no records",
                };
                this.$store.commit("ConfigModule/Alert", Alert);
              }
            }
            this.checkRecords = false;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          this.releaseItems = [];
          this.totalRecords = 0;
          this.releaseHoldBtn = true;
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Release Dialog
    releaseOrder() {
      if (this.selectedRelease.length !== 0) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        this.soArrayforHoldRelease = {
          SO: [],
        };
        for (var i = 0; i < this.selectedRelease.length; i++) {
          this.soArrayforHoldRelease.SO.push({
            so_id: this.selectedRelease[i].so_id,
            move_from: this.selectedAction,
          });
        }
        let json = JSON.stringify(this.soArrayforHoldRelease);
        let obj = {
          pass: "5",
          status: this.selectedAction,
          bu_key: parseInt(this.selectedBusiness),
          country: "",
          state: "",
          sotype_key: 0,
          part_key: 0,
          oware_key: 0,
          InputJson: json,
          user_key: parseInt(this.userId),
        };
        this.axios
          .post("sh/shipping_fulfillment_hold_release", obj)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "success",
                isShow: true,
                message: response.data.body.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              this.checkRecords = true;
              this.onSearch();
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              var Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
          });
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "Please select any Order#.",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //To add and remove From Fav
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //To brings page based actions to the page
    actionSetter(action) {
      if (this.actionList !== undefined && this.actionList !== null && this.actionList !== "") {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
  },
  components: {
    breadcrumbComp,
    BackToTop,
  },
};
